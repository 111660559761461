.app-cls[data-v-7aec4d0d] {
  padding-top: 1px;
  background: #f5f5f5;
  padding-bottom: 10px;
}
.app-cls[data-v-7aec4d0d]  .el-table {
  color: #333333;
  font-size: 13px;
}
.app-cls[data-v-7aec4d0d]  label {
  font-weight: 500;
  font-size: 13px;
}
.app-cls[data-v-7aec4d0d]  .el-button + .el-button {
  margin-left: 5px;
}
.app-cls[data-v-7aec4d0d]  .el-card__body {
  padding: 10px;
}
.app-cls[data-v-7aec4d0d] .el-col {
  min-height: 46px;
}
.app-cls[data-v-7aec4d0d]  .el-table--small td {
  padding: 8px 0;
}
.app-cls .normal-btn-cls[data-v-7aec4d0d]  .el-table--small td {
  padding: 5px 0;
}
.app-cls[data-v-7aec4d0d]  .el-card__header {
  padding: 8px 10px;
}
.app-cls .form-item-cls[data-v-7aec4d0d] {
  width: 100%;
  max-width: 320px;
}
.app-cls .header-cls[data-v-7aec4d0d] {
  margin: 10px 15px 0px 15px;
  background: #f5f5f5;
}
.app-cls .table-cls[data-v-7aec4d0d] {
  margin: 10px 15px 0px 15px;
  background: #f5f5f5;
}
.app-cls .table-cls[data-v-7aec4d0d]  .el-table__body tr.current-row > td {
  background: #edf5ff !important;
}
.app-cls .table-cls[data-v-7aec4d0d]  .el-table td {
  border-bottom: 1px solid #e9e9e9;
}
.app-cls .table-cls[data-v-7aec4d0d]  thead .cell {
  color: #333333;
}
.app-cls .table-cls[data-v-7aec4d0d]  .el-table__row .float-action-wrp {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  -webkit-transition: right, 0.2s;
  transition: right, 0.2s;
}
.app-cls .table-cls[data-v-7aec4d0d]  .el-table__row .float-action-wrp .float-action {
  display: block;
  padding: 0 6px 5px 6px;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  border-radius: 0 0 0 2px;
  white-space: nowrap;
  position: absolute;
  right: 0;
  bottom: -34px;
  background-color: #fff;
}
.app-cls .table-cls[data-v-7aec4d0d]  .el-table__row .float-action-wrp .float-action .el-button + .el-button {
  margin-left: 5px;
}
.app-cls .table-cls[data-v-7aec4d0d]  .el-table__row:hover td .float-action-wrp {
  display: block;
}
.app-cls .table-cls[data-v-7aec4d0d]  .el-table__row:hover td .float-action-wrp .float-action {
  background-color: #f5f7fa;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}
.app-cls .table-cls[data-v-7aec4d0d]  .el-table__row:last-child:not(:first-child) td .float-action-wrp .float-action {
  padding: 5px 6px 0 6px;
  top: -34px;
  bottom: auto;
  border-top: 1px solid #ebeef5;
  border-bottom: 0;
  border-radius: 2px 0 0 0;
}
.seachComStyle[data-v-7aec4d0d] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.seachComStyle > div:first-child span[data-v-7aec4d0d]:not(:first-child) {
  margin-left: 8px;
}
.seachComStyle > div:first-child span[data-v-7aec4d0d] {
  font-size: 13px;
}
.seachComStyle > div[data-v-7aec4d0d]:first-child .el-input,
.seachComStyle > div[data-v-7aec4d0d]:first-child .el-select {
  max-width: 165px;
}
.seachComStyle > div:first-child button[data-v-7aec4d0d] {
  margin-left: 8px;
}
.create-authorization[data-v-7aec4d0d] {
  position: relative;
  width: 100%;
  height: 100%;
}
.create-authorization .flex-between[data-v-7aec4d0d] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.create-authorization .from-information[data-v-7aec4d0d] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -48%);
          transform: translate(-50%, -48%);
  max-width: 450px;
  background-color: #fff;
  padding: 10px 40px;
  border-radius: 4px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 14px 0px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 14px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.create-authorization .from-information .active-select[data-v-7aec4d0d] {
  color: #1890ff;
  border-bottom: 1px solid #1890ff;
}
.create-authorization .el-form[data-v-7aec4d0d] {
  min-width: 370px;
  margin-top: 20px;
}
.create-authorization .el-form[data-v-7aec4d0d]  .el-form-item__label {
  font-size: 14px;
}
.create-authorization .el-form .btn[data-v-7aec4d0d] {
  width: 100%;
  font-size: 14px;
  border-radius: 20px;
}
.create-authorization .show-pwd[data-v-7aec4d0d] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  right: 12px;
  top: 1px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.create-authorization .codeImgBox[data-v-7aec4d0d] {
  width: 40%;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 5px;
  border: 1px solid #dcdfe6;
  overflow: hidden;
  border-radius: 4px;
}
.create-authorization .codeImgBox .refreshButton[data-v-7aec4d0d] {
  width: 40px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border-left: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.create-authorization .serviceBox[data-v-7aec4d0d] {
  width: 65px;
  height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  position: fixed;
  right: 2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #1890ff;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}
.create-authorization .serviceBox .wechatIcon[data-v-7aec4d0d] {
  width: 35px;
  height: 35px;
}
.create-authorization .hoverQcode[data-v-7aec4d0d] {
  padding: 10px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  right: 86px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #fff;
  font-size: 12px;
  line-height: 20px;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
}
.create-authorization .hoverQcode .qcode[data-v-7aec4d0d] {
  width: 132px;
  height: 132px;
}
.create-authorization .hoverQcode .arrow[data-v-7aec4d0d] {
  position: absolute;
  right: -18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px;
  color: #fff;
}