.mask[data-v-74da6fa4] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}
.header[data-v-74da6fa4] {
  position: fixed;
  top: 0;
  z-index: 500;
  width: 100%;
}
.signingbox[data-v-74da6fa4] {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.signatureBlock[data-v-74da6fa4] {
  position: absolute;
  top: 47%;
  left: 48%;
  color: #f1f1f1;
  font-weight: 700;
  font-size: 24px;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.backgrounds[data-v-74da6fa4] {
  position: fixed;
  width: 60px;
  width: 60px;
  height: 100%;
  background-color: #f4f6f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page[data-v-74da6fa4] {
  width: 100%;
  overflow: hidden;
  background-color: #f4f6f8;
}
.page .btn_container[data-v-74da6fa4] {
  width: 100vh;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f4f6f8;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.page .button[data-v-74da6fa4] {
  width: 200px;
  line-height: 20px;
  border-radius: 5px;
  margin: 0 50px;
}
.page .button-right[data-v-74da6fa4] {
  margin-left: 4px;
  color: white;
  background-color: #e40808;
  border: 1px solid #e40808;
  margin-left: 10px;
}
.canvasBox[data-v-74da6fa4] {
  width: 100%;
  overflow: hidden;
}
.button-wrapper[data-v-74da6fa4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-width: 180px;
}
.errTips[data-v-74da6fa4] {
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: fixed;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #eee;
  color: red;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: bold;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.errTips div[data-v-74da6fa4] {
  margin: 0 10px;
}