.messageDataList[data-v-7cb4fe82] {
  width: 100%;
  max-height: 500px;
  padding: 10px 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-y: scroll;
  white-space: nowrap;
}
.msg-list[data-v-7cb4fe82] {
  width: 270px;
  max-height: 500px;
  position: relative;
}
.msg-list .header[data-v-7cb4fe82] {
  width: 90%;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #eeee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.msg-list .item[data-v-7cb4fe82] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid #eeee;
}
.msg-list .msg-left[data-v-7cb4fe82] {
  font-weight: bold;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 45px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
}
.msg-list .msg-left > div[data-v-7cb4fe82] {
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}
.msg-list .msg-date[data-v-7cb4fe82] {
  margin-top: 5px;
  color: #999;
  font-size: 12px;
  font-weight: lighter;
}
.msg-list .bottom[data-v-7cb4fe82] {
  width: 86%;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #2150A3;
  text-align: center;
  cursor: pointer;
  background: #fff;
  border-top: 1px solid #eee;
}
.msg-list .fixedbottom[data-v-7cb4fe82] {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
}
.msg-box[data-v-7cb4fe82] {
  height: 100%;
  width: 42px;
  text-align: center;
  font-size: 24px;
  position: relative;
  margin-right: 5px;
  cursor: pointer;
}
.msg-box[data-v-7cb4fe82]:hover {
  background: rgba(0, 0, 0, 0.1);
}
.msg-box .spot[data-v-7cb4fe82] {
  margin-top: 13px;
  height: 24px;
  vertical-align: top;
  line-height: 0;
}
.el-popover[data-v-7cb4fe82] {
  padding: 5px !important;
}
.msgDialog[data-v-7cb4fe82]  .el-dialog__body {
  padding: 20px;
}
.msgDialog[data-v-7cb4fe82]  .el-dialog__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  justify-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.msgDialog[data-v-7cb4fe82]  .el-dialog__headerbtn {
  width: 18px;
  height: 18px;
  position: static;
}
.msgDialog .dialog-footer[data-v-7cb4fe82] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.msgDialog .dialogFooterTitle[data-v-7cb4fe82] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.msgDialog[data-v-7cb4fe82]  .el-dialog {
  border-radius: 20px;
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.23);
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.23);
  background: #f5f5f5;
}
.pageDialog[data-v-7cb4fe82] {
  margin-top: -5vh;
}
.pageDialog[data-v-7cb4fe82]  .el-dialog__footer {
  background: #f5f5f5;
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.pageDialog[data-v-7cb4fe82]  .el-dialog__body {
  padding: 10px 25px 10px;
  background: #f5f5f5;
}
.pageDialog[data-v-7cb4fe82]  .el-card__body {
  padding: 10px;
}
.pageDialog[data-v-7cb4fe82]  .el-dialog__header {
  line-height: 1.15 !important;
}
.file-box[data-v-7cb4fe82] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}
.file-box .file-text[data-v-7cb4fe82] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  color: #3434ff;
}